export const menuItems = [
  {
    name: "demos",
    label: "sections",
    items: [
      { name: "trainer", label: "The Academies/Trainers" },
      { name: "institutions", label: "The Institutions" },
      { name: "market", label: "The Market" },
      { name: "learner", label: "The Learner" },
      { name: "software", label: "The Infrastructure" },
    ],
  },
  {
    name: "pages",
    label: "links",
    items: [
      {
        name: "https://www.youtube.com/watch?v=5eW6Eagr9XA&t=47s",
        label: "build expertise,17-min",
        isExternal: true,
      },
      {
        name: "https://www.youtube.com/watch?v=GEmuEWjHr5c",
        label: "next revolution,7-min",
        isExternal: true,
      },
    ],
  },
];
