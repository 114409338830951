import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Title, Text, Span } from "../../components/Core";
import imgPhoto from "../../assets/image/sunil-photo.webp";
import imgPhoto2 from "../../assets/image/sharath-photo.webp";

// eslint-disable-line no-unused-vars
const LinkSocial = styled.a`
  color: grey !important;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 1.63px;
  transition: 0.4s;
  &:hover {
    color: blue !important;
    transform: translateY(-5px);
  }
`;

const About = ({ hero = true, bg = "dark", ...rest }) => {
  return (
    <>
      <Section hero={hero} bg={bg} {...rest}>
        <Container>
          <Row className="align-items-center">
            <Col lg="9">
              <div className="lg-9 pt-5 pt-lg-0">
                <Title color="light" variant="secSm">
                  Why Tuotr and Who we are
                </Title>
                <Text
                  color="light"
                  className="mt-3 mt-lg-9"
                  css={`
                    line-height: 1.25;
                  `}
                >
                  We have been in training for over{" "}
                  <LinkSocial
                    href="https://kathak.space"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    12 years.
                  </LinkSocial>{" "}
                  In that time we have struggled with training delivery,
                  assignments, tracking, discussions, fees and more. We have
                  tried several solutions and found them better suited for the
                  formal education sector, needing IT folks, tech experts, and
                  rigid processes.
                  <br />
                  <br />
                  We could not use systems that were based on content delivery
                  as these were just too complex, and most of our training was
                  live.
                  <br />
                  <br />
                  Even fee management, for example, did not work - one student
                  would pay late, another in bits at pieces, another would be
                  sponsored via donations.
                  <br />
                  <br />
                  Class schedules always changed, and there were many ad-hoc
                  sessions, especially during exams and performances. The
                  regular Learning Management Solutions (LMS) just fail
                  human-centered, person-to-person setups that are common in the
                  non-formal sector.
                  <br />
                  <br /> For years we struggled with Google Docs, Spreadsheets,
                  YouTube channels, Google Docs, Zoom and assorted utilities.
                  <br />
                  <br />
                  Over time we just could not manage this disjointed collection
                  of free and paid tools. We decided we had to build something
                  for all of us. <br />
                  <br />
                  Thus was born Tuotr.
                </Text>
              </div>
            </Col>
          </Row>
          &nbsp;
          <Row className="align-items-center">
            <Col lg="6">
              <div>
                <img src={imgPhoto} alt="folio" className="img-fluid" />
              </div>
            </Col>
            <Col lg="6">
              <div className="pl-lg-4 pt-5 pt-lg-0">
                <Title color="light" variant="secSm">
                  Sunil Gupta
                </Title>
                <Text color="light" variant="small">
                  CEO, CTO, Product Owner
                </Text>
                <Text
                  color="light"
                  className="mt-3 mt-lg-5"
                  css={`
                    line-height: 1.5;
                  `}
                >
                  Building software products for Small Businesses from 1990.
                </Text>
                <Text color="light" className="mt-4">
                  I understand how making things simple for users implies the
                  software must do the hard work. After all complexity cannot be
                  reduced, someone must take care of it.
                </Text>
                <div className="mt-4">
                  <Text color="light">Email me at</Text>

                  <Text variant="p">
                    <a
                      href="mailto:support@tuotr.com"
                      className="font-weight-bold"
                    >
                      <Span color="light">support@tuotr.com</Span>
                    </a>
                  </Text>
                </div>

                <div className="mt-5 d-flex">
                  <LinkSocial
                    href="https://www.linkedin.com/in/insunilgupta/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-weight-bold"
                  >
                    LinkedIn
                  </LinkSocial>
                </div>
              </div>
            </Col>
          </Row>
          &nbsp;
          <Row className="align-items-center">
            <Col lg="6">
              <div>
                <img src={imgPhoto2} alt="folio" className="img-fluid" />
              </div>
            </Col>
            <Col lg="6">
              <div className="pl-lg-4 pt-5 pt-lg-0">
                <Title color="light" variant="secSm">
                  Sharath Babu
                </Title>
                <Text color="light" variant="small">
                  CMO, UI/UX
                </Text>
                <Text
                  color="light"
                  className="mt-3 mt-lg-5"
                  css={`
                    line-height: 1.5;
                  `}
                >
                  Working on simplifying technology from 1999.
                </Text>
                <Text color="light" className="mt-4">
                  Eliminating user guides, training and fine-print has been my objective from 25 years. It's a never-ending journey since user expectations keep growing faster, and technology keeps getting complex - but UI/UX is what gives me fuel!
                </Text>
                <div className="mt-4">
                  <Text color="light">Email me at</Text>

                  <Text variant="p">
                    <a
                      href="mailto:support@tuotr.com"
                      className="font-weight-bold"
                    >
                      <Span color="light">support@tuotr.com</Span>
                    </a>
                  </Text>
                </div>

                <div className="mt-5 d-flex">
                  <LinkSocial
                    href="https://www.linkedin.com/in/babusharath/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-weight-bold"
                  >
                    LinkedIn
                  </LinkSocial>
                </div>
              </div>
            </Col>
          </Row>
          
        </Container>
      </Section>
    </>
  );
};

export default About;
